// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    margin-right: 10px;
    background-color: green;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .button:hover {
    background-color: darkgreen;
  }
  
  .button:active {
    transform: scale(0.95);
  }


  .text-input {
    background-color: #ffd580;  /* Set initial background color */
    border: 1px solid #ccc;
    padding: 8px;
    font-size: 16px;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }

  .text-input:hover {
    background-color: #ff9f00; /* Color when hovering */
  }`, "",{"version":3,"sources":["webpack://./src/pages/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,0CAA0C;IAC1C,2DAA2D;EAC7D;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,sBAAsB;EACxB;;;EAGA;IACE,yBAAyB,GAAG,iCAAiC;IAC7D,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,iCAAiC,EAAE,uCAAuC;EAC5E;;EAEA;IACE,yBAAyB,EAAE,wBAAwB;EACrD","sourcesContent":[".button {\r\n    margin-right: 10px;\r\n    background-color: green;\r\n    color: white;\r\n    padding: 5px 10px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\r\n    transition: background-color 0.3s ease, transform 0.2s ease;\r\n  }\r\n  \r\n  .button:hover {\r\n    background-color: darkgreen;\r\n  }\r\n  \r\n  .button:active {\r\n    transform: scale(0.95);\r\n  }\r\n\r\n\r\n  .text-input {\r\n    background-color: #ffd580;  /* Set initial background color */\r\n    border: 1px solid #ccc;\r\n    padding: 8px;\r\n    font-size: 16px;\r\n    transition: background-color 0.3s; /* Smooth transition for hover effect */\r\n  }\r\n\r\n  .text-input:hover {\r\n    background-color: #ff9f00; /* Color when hovering */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
