import React, { useState } from "react";


const FileUploadWithDropdown = () => {
    const [selectedBank, setSelectedBank] = useState("GLS"); // Default selected value
    const [file, setFile] = useState(null);
    const [result, setResult] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleBankChange = (event) => {
        setSelectedBank(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            alert("Please select a file before uploading!");
            return;
        }

        const formData = new FormData();
        formData.append("csvFile", file);
        formData.append("bank", selectedBank);
        try {
            const config = await fetch('/frontendConfig.json').then(response => response.json());
            const hostAddress = `${config.serverAddress}/upload`;
            console.log(`sending POST to: ${hostAddress}`);
            const response = await fetch(`${hostAddress}`, {
                method: "POST",
                body: formData,
            });
            const result = await response.json();
            setResult(result);
            console.log(result.transactions);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };


    return (
        <div>
            <h1>Bank Analysis</h1>
            <form onSubmit={handleSubmit} style={{ display: "flex", gap: "10px" }}>
                <div>
                    <label htmlFor="fileInput">Upload CSV:</label>
                    <input
                        id="fileInput"
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                    />
                </div>
                <div>
                    <label htmlFor="bankSelect">Bank:</label>
                    <select id="bankSelect" value={selectedBank} onChange={handleBankChange}>
                        <option value="GLS">GLS</option>
                        <option value="DKB">DKB</option>
                    </select>
                </div>
                <button type="submit">Upload</button>
            </form>

            {result && result.transactions && result.transactions.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Betrag</th>
                            <th>Beschreibung</th>
                            <th>Kategorie</th>
                        </tr>
                    </thead>
                    <tbody>
                        {result.transactions.map((row, index) => (
                            <tr key={index}>
                                <td>{row.Datum}</td>
                                <td>{row.Betrag}</td>
                                <td>{row.Beschreibung}</td>
                                <td>{row.Kategorie}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No transactions to display</p>
            )}
        </div>
    );
};

export default FileUploadWithDropdown;